<template>
  <div>
    <b-alert
      v-model="showMessage"
      variant="success"
      dismissible
    >
      <div class="alert-body">
        <span>{{ message }}</span>
      </div>
    </b-alert>
    <b-card-actions
      ref="cardAction"
      title="Filters"
      @refresh="resetColFilters"
      @remove="resetColFilters"
    >
      <b-row>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <vc-date-picker
            v-model="start"
            mode="dateTime"
            :model-config="startModelConfig"
            is24hr
          >
            <template v-slot="{ inputValue, inputEvents }">
              <label>From date</label>
              <input
                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 form-control"
                :value="inputValue"
                placeholder="From date"
                v-on="inputEvents"
              >
            </template>
          </vc-date-picker>
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="mb-1"
        >
          <vc-date-picker
            v-model="end"
            mode="dateTime"
            :model-config="endModelConfig"
            is24hr
          >
            <template v-slot="{ inputValue, inputEvents }">
              <label>To date</label>
              <input
                class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 form-control"
                :value="inputValue"
                placeholder="To date"
                v-on="inputEvents"
              >
            </template>
          </vc-date-picker>
        </b-col>
        <b-col
          cols="12"
          md="1"
          class="mb-md-0 mb-2 mt-2"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            class="btn-icon"
            @click="handleSearch"
          >Search
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="1"
          class="mb-md-0 mb-2 mt-2"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.report-email
            variant="primary"
            class="btn-icon"
          >
            Export
          </b-button>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-row class="match-height">
      <b-col md="6">
        <b-card>
          <b-card-title>Sales Chart</b-card-title>
          <b-overlay
            variant="white"
            :show="showeLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".28"
            rounded="sm"
          >
            <e-charts
              ref="pie"
              autoresize
              :options="pie"
              theme="theme-color"
              auto-resize
            />
          </b-overlay>
        </b-card>
      </b-col>
      <b-col
        md="6"
        class="mb-md-0 mb-2"
      >
        <b-card>
          <b-card-title>Sales Report</b-card-title>
          <b-overlay
            variant="white"
            :show="saleLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".28"
            rounded="sm"
          >
            <b-table-simple
              hover
              striped
              bordered
              responsive
              class="rounded-bottom mb-0"
            >
              <b-tbody>
                <b-tr>
                  <b-td>Sales (MYR)</b-td>
                  <b-td>{{ total_sale }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Profit</b-td>
                  <b-td>{{ total_profit }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Successful</b-td>
                  <b-td>{{ total_success }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Failed</b-td>
                  <b-td>{{ total_failed }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Pending</b-td>
                  <b-td>{{ total_pending }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>Downline User</b-td>
                  <b-td>{{ total_user }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td>User Payout</b-td>
                  <b-td>{{ total_payout }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-card ref="operatorcard">
      <!--  <b-overlay
                    variant="white"
                    :show="showOpertorWiseLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".28"
                    rounded="sm"
                  > -->
      <b-card-title>Summary Report</b-card-title>
      <div
        v-show="showOpertorWiseLoading"
        class="text-center text-primary my-2"
      >
        <b-spinner class="align-middle" />
        <strong>This will only take a moment. Please wait...</strong>
      </div>
      <template
        v-for="(operatorType,name) in summaryList"
        v-if="!showOpertorWiseLoading"
      >
        <b-card>
          <b-card-title>{{ name }}</b-card-title>
          <b-tabs
            vertical
            nav-wrapper-class="nav-vertical"
          >
            <template v-for="(status,statusName) in operatorType">
              <b-tab :title="statusName">
                <b-card-text>
                  <transection :data="status" />
                </b-card-text>
              </b-tab>
            </template>
          </b-tabs>
        </b-card>
      </template>
      <!-- </b-overlay> -->
    </b-card>
    <b-row>
      <b-col md="6">
        <b-card>
          <b-card-title> Debit Notes</b-card-title>
          <b-table-simple
            hover
            striped
            bordered
            responsive
            class="rounded-bottom mb-0"
          >
            <b-thead head-variant="light">
              <b-tr>
                <b-th>Count</b-th>
                <b-th>MRP [MYR]</b-th>
                <b-th>Amount [MYR]</b-th>
                <b-th>Type</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-if="debitNoteList.length>0">
                <b-tr
                  v-for="(tr, indextr) in debitNoteList"
                  :key="indextr"
                >
                  <b-td>
                    {{ tr.tr_count }}
                  </b-td>
                  <b-td>
                    {{ tr.org_amt }}
                  </b-td>
                  <b-td>
                    {{ tr.amt }}
                  </b-td>
                  <b-td>
                    {{ tr.type }}
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <b-tr rowspan="10">
                  <b-td
                    colspan="4"
                    class="text-center"
                  >
                    No data avilable
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <b-card-title> E-Transfers</b-card-title>
          <b-table-simple
            hover
            striped
            bordered
            responsive
            class="rounded-bottom mb-0"
          >
            <b-thead head-variant="light">
              <b-tr>
                <b-th>Count</b-th>
                <b-th>MRP [MYR]</b-th>
                <b-th>Amount [MYR]</b-th>
                <b-th>Type</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-if="transferList.length>0">
                <b-tr
                  v-for="(tr, indextr) in transferList"
                  :key="indextr"
                >
                  <b-td>
                    {{ tr.tr_count }}
                  </b-td>
                  <b-td>
                    {{ tr.org_amt }}
                  </b-td>
                  <b-td>
                    {{ tr.amt }}
                  </b-td>
                  <b-td>
                    {{ tr.type }}
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <b-tr rowspan="10">
                  <b-td
                    colspan="4"
                    class="text-center"
                  >
                    No data avilable
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </b-card>
      </b-col>
    </b-row>
    <validation-observer
      ref="emailreport"
      tag="form"
    >
      <b-modal
        id="report-email"
        modal-class="modal-success"
        centered
        title="Report Send To Email"
      >
        <b-card-text>
          <label>Email</label>
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              id="email"
              v-model="other_email"
              placeholder="Enter Email"
              :class="errors.length > 0 ? 'is-invalid' : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-card-text>
        <template #modal-footer>
          <b-button
            size="sm"
            variant="success"
            @click="exportReport"
          >
            Send
          </b-button>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>
<script>
/* eslint-disable import/no-unresolved */
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import theme from '@core/components/charts/echart/theme.json'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BTableSimple,
  BCard,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BTabs,
  BTab,
  BFormInput,
  BModal,
  BCardText,
  BCardTitle,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import UserReportService from '../../services/user.report.service'
import Transection from './summary_component/transection.vue'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ECharts,
    BTableSimple,
    BCard,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BOverlay,
    BCardActions,
    BTabs,
    BTab,
    BCardText,
    BCardTitle,
    BSpinner,
    BAlert,
    BModal,
    Transection,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dateFromFilter: null,
      dateToFilter: null,
      showOpertorWiseLoading: false,
      showeLoading: false,
      saleLoading: false,
      required,
      email,
      total_sale: 0,
      total_success: 0,
      total_profit: 0,
      total_failed: 0,
      total_pending: 0,
      total_user: 0,
      total_payout: 0,
      date: '',
      userOptions: [],
      start: '',
      end: '',
      startModelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss', // Uses 'iso' if missing
        timeAdjust: '00:00:00',
      },
      endModelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss', // Uses 'iso' if missing
        timeAdjust: '23:59:00',
      },
      other_email: '',
      loginid: '',
      selected: [],
      status: [
        'Failed',
        'InProcess',
        'Online Pin Issue',
        'Success',
      ],
      ranksqlrowarray: ['InProcess', 'Refunded', 'Success'],
      prepaid: [],
      postpaid: [],
      games: [],
      pin: [],
      government: [],
      product_registration: [],
      itemsPerPage: 25,
      isMounted: false,
      addNewDataSidebar: false,
      reloadCount: [],
      issuePinCount: '',
      debitCount: '',
      transferCount: '',
      showMessage: false,
      message: '',
      summary: [],
      success: [],
      inprocess: [],
      pie: {
        tooltip: {
          trigger: 'item',
          formatter(params) {
            return `${params.seriesName}<br />
                    ${params.name}: ${params.data.value} (${params.percent}%)<br />
                    ${params.data.name1}: ${params.data.value1}<br />
                    ${params.data.name2}: ${params.data.value2}`
          },
        },
        legend: {
          left: 10,
          bottom: '0',
          data: ['Success', 'InProcess', 'Online Pin Issue', 'Failed'],
        },
        series: [{
          name: 'Transactions',
          type: 'pie',
          radius: '55%',
          color: ['#EA5455', '#FF9F43', '#7367F0', '#28C76F'],
          data: [],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        }],
      },
    }
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    listSuccess() {
      return this.success
    },
    listRefund() {
      return this.refunded
    },
    listInprocess() {
      return this.inprocess
    },
    reloadList() {
      return this.reloadCount
    },
    debitNoteList() {
      return this.debitCount
    },
    transferList() {
      return this.transferCount
    },
    queriedItems() {
      return this.totalItems
    },
    summaryList() {
      return this.summary
    },
    totalCredited() {
      return this.summary.reduce((total, item) => {
        if (item.type === 'C') {
          return total + parseInt(item.org_amt, 10)
        }
        return total
      }, 0)
    },
    totalDebited() {
      return this.summary.reduce((total, item) => {
        if (item.type === 'D') {
          return total + parseInt(item.org_amt, 10)
        }
        return total
      }, 0)
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
  },
  mounted() {
    this.isMounted = true
    this.getReloadCount()
    this.getDebitNoteCount()
    this.getTransferCount()
    this.getSummaryInfo()
    this.getSale()
  },
  methods: {
    getColor(value) {
      if (parseInt(value) === 0) {
        return '#999'
      } if (parseInt(value) > 0) {
        return 'success'
      }
    },
    getStatusColor(status) {
      if (status === 'Success') return 'success'
      if (status === 'Transfered') return 'success'
      if (status === 'Refunded') return 'warning'
      if (status === 'InProcess') return 'warning'
      if (status === 'Failed') return 'danger'
      if (status === 'Online Pin Issue') return '#7367F0'
      return 'success'
    },
    changeLenght(data) {
      this.itemsPerPage = data
      this.getSummaryInfo({
        limit: this.itemsPerPage,
      })
    },
    handleSearch() {
      if (this.start && this.end) {
        this.date = `${this.start}#${this.end}`
        if (this.start < this.end) {
          this.getSummaryInfo({
            date: this.date,
          })
          this.getReloadCount({
            date: this.date,
          })
          this.getDebitNoteCount({
            date: this.date,
          })
          this.getTransferCount({
            date: this.date,
          })
          this.getSale({ date: this.date })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Please change start and end date',
              icon: 'MessageSquareIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please Select To and from date',
            icon: 'MessageSquareIcon',
            variant: 'danger',
          },
        })
      }
      // this.$refs.prepaid.getInfo(this.date);
    },
    handleChangePage() {
      this.getSummaryInfo({
        page: this.userData.current_page,
        date: this.date,
        loginid: this.loginid,
      })
    },
    handleSort() {},
    resetColFilters() {
      const date = new Date()
      this.start = date.setHours(0, 0, 0)
      this.end = date.setHours(23, 59, 0)
      this.loginid = ''
      this.getSummaryInfo()
      this.getReloadCount()
      this.getDebitNoteCount()
      this.getTransferCount()
      this.getSale()
      this.$refs.cardAction.showLoading = false
    },
    getSummaryInfo(data) {
      this.showOpertorWiseLoading = true
      UserReportService.getSummaryInfo(data).then(
        response => {
          this.summary = response.summary
          this.showOpertorWiseLoading = false
        },
        () => {
          this.showOpertorWiseLoading = false
        },
      )
    },
    getSale(data) {
      this.saleLoading = true
      UserReportService.getTotalTransectionByDate(data).then(
        response => {
          this.total_sale = response.total_sale
          this.total_profit = response.total_profit
          this.total_success = response.total_success
          this.total_failed = response.total_failed
          this.total_pending = response.total_pending
          this.total_user = response.total_user
          this.total_payout = response.total_payout
          this.saleLoading = false
        },
        () => {},
      )
    },
    getReloadCount(data) {
      this.showeLoading = true
      UserReportService.getReloadCount(data).then(
        response => {
          this.reloadCount = response.reloadCount

          this.pie.series[0].data = []
          const self = this
          this.status.map(value => self.pie.series[0].data.push(
            {
              value: response[value].tr_count, name: value, name1: 'Profit', value1: response[value].profit, name2: 'Amount', value2: response[value].amt,
            },
          ))
          this.showeLoading = false
        },
        () => {
          this.showeLoading = false
        },
      )
    },
    getDebitNoteCount(data) {
      UserReportService.getDebitNoteCount(data).then(
        response => {
          this.debitCount = response.debitNoteCount
        },
        () => {},
      )
    },
    getTransferCount(data) {
      UserReportService.getTransferCount(data).then(
        response => {
          this.transferCount = response.transferCount
          this.dateFromFilter = response.fromdate
          this.dateToFilter = response.todate
          this.end = response.todate
          this.start = response.fromdate
        },
        () => {},
      )
    },
    exportReport() {
      if (this.start && this.end) {
        this.date = `${this.start}#${this.end}`
        if (this.start < this.end) {
          this.$refs.emailreport.validate().then(success => {
            if (success) {
              UserReportService.exportSummaryInfo({
                date: this.date,
                loginid: this.loginid,
                email: this.other_email,

              }).then(
                response => {
                  this.showMessage = true
                  this.message = response.message
                  this.$bvModal.hide('report-email')
                },
                () => {
                },
              )
            }
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: 'Please change start and end date',
              icon: 'MessageSquareIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please Select To and from date',
            icon: 'MessageSquareIcon',
            variant: 'danger',
          },
        })
      }
    },
  },

}

</script>
<style lang="scss" scoped>
@import '~@core/scss/vue/libs/chart-apex.scss';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.echarts {
    width: 100% !important
}

.card {
    ::v-deep .card-header {
        .heading-elements {
            position: static;
            cursor: inherit;

            .list-inline {
                display: block;

                li {
                    a {
                        padding: 0;
                    }

                    &:not(:last-child) {
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}

.dark-layout {
    .b-overlay-wrap ::v-deep .b-overlay {

        // border: 10px solid red;
        .bg-white {
            background-color: $theme-dark-body-bg !important;
        }
    }
}

</style>
